import React from 'react';
import './process.css'
import {Card, ListGroup, ListGroupItem, Button} from 'react-bootstrap';
import {ReactComponent as OnGoingSupportLogo} from "../../assets/OnGoingSupport.svg";
import {ReactComponent as DiscoveryLogo} from "../../assets/Discovery.svg";
import {ReactComponent as ProjectDevlieryLogo} from "../../assets/ProjectDevliery.svg";
import {ReactComponent as OutgoingSupportLogo} from "../../assets/OutgoingSupport.svg";
import {ReactComponent as OutlineLogo} from "../../assets/Outline.svg";


const scrollToForm = () => {
    const leadForm = document.getElementById('leadform-wrapper');
    if (leadForm)
        leadForm.scrollIntoView({block: 'start', behavior: 'smooth'});
}

const process_objects = [
    {
        title: 'Discovery',
        description: 'No charge meetup where you can share your focus areas and what you are looking for in terms of solutions. Together, we will identify priority opportunities to help define tasks and scope.',
        logo: DiscoveryLogo
    },
    {
        title: 'Outline',
        description: 'Vertical Axis provides you with a clearly defined and transparent outline of scope, deliverables, dates, and fees for review and refinement with you.',
        logo: OutlineLogo
    },
    {
        title: 'Project Delivery',
        description: 'Its time to deploy our solutions! Vertical Axis will review with you and your teams as well as provide usage and ownership training.',
        logo: ProjectDevlieryLogo
    },
    {
        title: 'Ongoing Support',
        description: 'Vertical Axis will check in with you to ensure our solutions are working for you and your company. We are committed to providing rapid ongoing support for all solutions provided.',
        logo: OutgoingSupportLogo
    },
]

const processObjects = process_objects.map((item, key) =>
    <>
        <div className={'process-object ' + 'process-object-' + key.toString()}>
            <div className={'process-object-title'}>
                <h2>{item.title}</h2>
            </div>
            <div className={'process-object-description'}>
                <p>{item.description}</p>
            </div>
            {item.title == 'Contact Us' &&
            <button onClick={scrollToForm}>Contact Us</button>}
        </div>
    </>
);

export default function Process() {

    return (
        <div className={'process-wrapper'} id={'process-wrapper'}>

            <div className={'process-content'}>

                <div className={'process-content-title'}>
                    <h1>Our Process</h1>
                </div>

                <div className={'process-items'}>
                    <div className={'process-content-object'}>
                        {process_objects.map(item => (
                            <Card style={{ border: 'none', backgroundColor:'#F2F3F4'}} className={'process-card'}>
                                <item.logo style={{ width:'80px', height:'80px'}}/>
                                <h5 style={{ width:'100%', borderBottom:"2px solid #000000", marginTop:'10px'}}>{item.title}</h5>
                                <p>{item.description}</p>
                            </Card>
                            ))}

                        
                        {/* 
                        <Card style={{ border: 'none'}} className={'process-card'}>
                            <DiscoveryLogo style={{ width:'80px', height:'80px'}}/>
                            <h5 style={{ width:'100%', borderBottom:"2px solid #000000", marginTop:'10px'}}>Discovery</h5>
                            <p>No charge meetup where you can share your focus areas and what you are looking for in terms of solutions. Together, we will identify priority opportunities to help define tasks and scope.</p>
                            
                        </Card>
                        
                        
                        <Card style={{ border: 'none'}} className={'process-card'}>
                            <DiscoveryLogo style={{ width:'80px', height:'80px'}}/>
                            <h5>Discovery</h5>
                            <p>No charge meetup where you can share your focus areas and what you are looking for in terms of solutions. Together, we will identify priority opportunities to help define tasks and scope.</p>
                            
                        </Card>

                        <Card style={{ border: 'none'}} className={'process-card'}>
                            <DiscoveryLogo style={{ width:'80px', height:'80px'}}/>
                            <h5>Discovery</h5>
                            <p>No charge meetup where you can share your focus areas and what you are looking for in terms of solutions. Together, we will identify priority opportunities to help define tasks and scope.</p>
                            
                        </Card>

                        <Card style={{ border: 'none'}} className={'process-card'}>
                            <DiscoveryLogo style={{ width:'80px', height:'80px'}}/>
                            <h5>Discovery</h5>
                            <p>No charge meetup where you can share your focus areas and what you are looking for in terms of solutions. Together, we will identify priority opportunities to help define tasks and scope.</p>
                            
                        </Card> */}

                        
                    </div>
                </div>
                <div className={'process-content-posttext'}>
                    <h1>Get started with us today</h1>
                    <button onClick={scrollToForm} id={'submit_button'} className={'pre-submit'} type={'submit'} style={{ fontWeight:'bold'}}>Contact Us</button>
                </div>
                


            </div>
        </div>
    );
}