import React from 'react';
import './hero.css';

const scrollToNavBar = () => {
    const navBar = document.getElementById('navbar-wrapper');
    if (navBar)
        navBar.scrollIntoView({block: 'start', behavior: 'smooth'});
}

export default function Hero() {

    const titleOne = "Business, simplified."

    const paragraphOne = "Building the operational systems you need for your business to succeed. Our drive is to create accessible solutions that save you time, money, reduce overall risk exposure and protect your company's vision.";
    const paragraphTwo = "Creating accessible solutions that save you time and money.";
    const paragraphThree = "Creating accessible solutions that save you time, and money. Let us help you move forward with your business.";

    const buttonOne = "Find Out More";

    return (
        <div className={'hero'}>
            <div className={'hero-content'}>
                <h1 className={'hero-title'}>{titleOne}</h1>
                <p className={'hero-paragraph'}>{paragraphTwo}</p>
                <button className={'hero-button'} onClick={scrollToNavBar}>{buttonOne}</button>
            </div>
        </div>
    );

}