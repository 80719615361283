import React, {CSSProperties} from 'react';
import './services.css'

type ServicesProps = {
    skew: string,
};

export default function Services({skew}: ServicesProps) {

    const style_skew: CSSProperties = {
        transform: `skewY(-${skew})`
    };

    const style_unskew = {
        transform: `skewY(${skew})`
    };

    const test = ''

    const services = [
        {
            title: 'Online Store Implementation',
            description: 'We can help you reach more customers by getting you setup to sell your products online through\n' +
                'an existing platform (ex. Shopify). We build the integrations and handle setup to ensure you can sell\n' +
                'your goods online safely and securely.'
        },
        {
            title: 'Booking and Scheduling Solutions',
            description: 'Time is money. Your customers need an easy scheduling tool and you need an efficient way to\n' +
                'manage that tool. Let us rapidly deploy and integrate a custom or out-of-the-box solution (ex.\n' +
                'Schedulicity) so you can take back your time!'
        },
        {
            title: 'Accounting Systems',
            description: 'Balancing the books shouldn\'t be hard work or break the bank. We can implement or upgrade\n' +
                'your current solution, saving you time and stress. We build to your specific needs – whether that’s a free\n' +
                'solution like Wave or a more robust solution like QuickBooks, we’ve got you covered.'
        },
        {
            title: 'Invoicing Systems',
            description: 'Getting paid for the goods and services you provide shouldn\'t be a hassle. Let us help identify and\n' +
                'transition you onto a streamlined solution that fits your needs, reduces your workload, and helps reduce\n' +
                'ageing receivables and the resulting cashflow crunch.'
        },
        {
            title: 'Customer Relationship Management',
            description: 'Keep track of the business relationships that matter most. Whether you need a basic / low cost\n' +
                'solution to help you keep track of leads and customers or a top-tier solution like Salesforce, we can build\n' +
                'and implement it.'
        },
        {
            title: 'Reporting Tools',
            description: 'Where are you missing visibility? Not sure? We can help identify your blind spots and build\n' +
                'reports to bring them into visibility so there are no surprises. Visibility over what matters most leads to\n' +
                'better choices for your business.'
        },
        {
            title: 'Delivery and Logistics',
            description: 'Getting product to your customers on time, accurately, and with visibility is paramount. We can\n' +
                'help identify and build existing market leading software into your operations. Save time and money with\n' +
                'efficient route planning and real-time integrations with suppliers, customers, and shippers.'
        },
        {
            title: 'Integrated Template Creation',
            description: 'Getting it right the first time and doing it once will showcase your reliability and save you time.\n' +
                'Our team has experience upgrading existing systems and / or creating tailored templates where\n' +
                'necessary. Input / field validation, professional appearance, and ease of use is what we deliver!'
        },
    ]

    const servicesObjects = services.map((item, key) =>
        <>
            <div className={'service-object'}>
                <div className={'service-object-title'}>
                    <h2>{item.title}</h2>
                </div>
                <div className={'service-object-description'}>
                    <p>{item.description}</p>
                </div>
            </div>
        </>
    );

    // TODO
    // Service title needs some serious decorative love.
    return (
        // <div className={'services-wrapper'} style={style_skew}>
        <div className={'services-section-wrapper'} id={'services-wrapper'}>
            {/*<div className={'services-content-wrapper'} style={style_unskew}>*/}
            <div className={'services-content-wrapper'}>
                <div className={'services-content-title'}>
                    <h1>Services</h1>
                </div>
                <div className={'services-content-objects'}>
                    {servicesObjects}
                </div>
            </div>
        </div>
    );

}