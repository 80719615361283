import React from 'react';
// import ReactGA from 'react-ga';

import './App.css';

import Hero from "./components/hero/hero";
import NavBar from "./components/navbar/navbar";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Process from "./components/process/Process";
import Pricing from "./components/pricing/Pricing";
import LeadForm from "./components/leadform/leadform";
import Footer from "./components/footer/Footer";
import Testimonial from './components/testimonial/Testimonial';

const skewAngle = '3.5deg'

function App() {

    // ReactGA.initialize('G-SDHPL38MWK');

    return (
        <div className="app">
            <Hero/>
            <NavBar/>
            <About/>
            <Services
                skew={skewAngle}
            />
            <Process/>
            <Pricing/>
            <Testimonial/>
            <LeadForm/>
            <Footer/>
        </div>
    );
}

export default App;
