import React, {CSSProperties} from 'react';
import './pricing.css';
import {Card, ListGroup, ListGroupItem, Button} from 'react-bootstrap';

const scrollToForm = () => {
    const leadForm = document.getElementById('leadform-wrapper');
    if (leadForm)
        leadForm.scrollIntoView({block: 'start', behavior: 'smooth'});
};

type PricingProps = {
    skew: string,
};

export default function Pricing() {

    // const style_skew: CSSProperties = {
    //     transform: `skewY(-${skew})`
    // };
    //
    // const style_unskew = {
    //     transform: `skewY(${skew})`
    // };

    const p1 = 'Pricing provided below is  a general guideline as to what you might expect to pay for our services, depending on how many hours of engagement will be required by our Lead Consultants (pricing includes some hours for non-lead tasks).';
    const p2 = 'The truth is, it depends…  It depends on deadline requirements you may have, the size of the tasks involved, and the overall complexity of the desired solution.';
    const p3 = 'We’d like to entertain some initial discussions with you to develop accurate pricing for the custom solution(s) you desire.  Follow up and book your appointment today!';

    const pTop = 'Pricing below is based on examples of common projects Vertical Axis takes on. Accurate pricing can only\n' +
        'be provided after an initial project discussion.'

    const pBottom = 'As above, these examples are meant as a guideline, the truth is, it depends… It depends on deadline\n' +
        'requirements you may have, the size of the tasks involved, and the overall complexity of the desired\n' +
        'solution(s).\n' +
        'These all factor in to how many hours we will quote to provide your specific solution(s). Further, the mix\n' +
        'of Lead Consultant hours versus Analyst hours will factor into the overall quote.\n' +
        'We’d like to entertain some initial discussions with you to develop accurate pricing for the custom\n' +
        'solution(s) you desire. Follow up and book your appointment today!'

    const ptiers = [
        {
            title: 'Small',
            hours: '8-10 hours',
            price: '$1,400 - $1,750'
        },
        {
            title: 'Medium',
            hours: '20-25 hours',
            price: '$3,000 - $3,750',
        },
        {
            title: 'Large',
            hours: '35-40 hours',
            price: '$4,375 - $5,000'
        },
    ]

    const ptiersMarkup = ptiers.map((item, key) =>
        <div className={'pricing-tiers-object'}>
            <h2>{item.title}</h2>
            <h3>{item.hours}</h3>
            <h3>{item.price}</h3>
        </div>
    );

    const pricing_tiers = [
        {
            title: 'Small Project',
            example: 'Ex. Implement an online scheduling tool.',
            hours: '5 - 10 hours',
        },
        {
            title: 'Medium Project',
            example: 'Ex. Roll out an online storefront and integrate with current backend/accounting software.',
            hours: '10 - 20 hours',
        },
        {
            title: 'Large Project',
            example: 'Ex. Accounting system review, selection, implementation, and integration with existing operations.',
            hours: '20 - 50 hours+',
        },
    ];

    const pricing_tiers_markup = pricing_tiers.map((item, key) =>
        <div className={'pricing-object ' + 'price-ob-' + key}>
            <h2>{item.title}</h2>
            <p>{item.example}</p>
            <p>{item.hours}</p>
        </div>
    );


    return (
        <div className={'pricing-wrapper'} id={'pricing-wrapper'}>
            <div className={'pricing-content-wrapper'}>

                <div className={'pricing-content-title'}>
                    <h1>Let’s find a way together</h1>
                </div>

                <div className={'pricing-content-pretext'}>
                    <p>{pTop}</p>

                    <h2>Our Rates</h2>
                    <hr/>
                    <p><span>Analyst rate:</span> $40 - $60 / hr</p>
                    <p><span>Lead Consultant rate:</span> $125 - $175 / hr</p>
                </div>

                <div className={'pricing-content-objects'}>
                    <div className={'pricing-content-object'}>
                        <Card style={{ border: 'none'}} className={'pricing-card'}>
                            <Card.Body style={{ padding: '0px'}} >
                                <Card.Title style={{marginBottom:'0px'}}>
                                    <div className={'pricing-card-header-small'}>
                                        <h2 className={'pricing-header-text1'}>Small Project</h2>
                                        <h3 className={'pricing-header-text2'}>1-10 hours</h3>
                                    </div>
                                </Card.Title>
                            </Card.Body>
                            <ListGroup style={{ color: '#000000', minWidth:'250px', textAlign:'center'}} className={'pricing-content-list'}>
                            <ListGroupItem>Scheduling tool setup</ListGroupItem>
                                <ListGroupItem>Invoicing solutions</ListGroupItem>
                            </ListGroup>
                            <Card.Body style={{ minWidth:'250px'}}>
                                <Button  onClick={scrollToForm}  variant="primary" size="sm" block style={{ backgroundColor: '#5B8A5D'}}>
                                    Get Started
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                    

                    <div className={'pricing-content-object'}>
                        <Card style={{ border: 'none'}} className={'pricing-card'}>
                            <Card.Body style={{ padding: '0px'}} >
                                <Card.Title style={{marginBottom:'0px'}}>
                                    <div className={'pricing-card-header-medium'}>
                                        <h2 className={'pricing-header-text1'}>Medium Project</h2>
                                        <h3 className={'pricing-header-text2'}>10-20 hours</h3>
                                    </div>
                                </Card.Title>
                            </Card.Body>
                            <ListGroup style={{ color: '#000000', minWidth:'250px', textAlign:'center'}} className={'pricing-content-list'}>
                            <ListGroupItem>Online storefront & backend integration</ListGroupItem>
                                <ListGroupItem>CRM tools</ListGroupItem>
                                <ListGroupItem>Reporting upgrades</ListGroupItem>
                            </ListGroup>
                            <Card.Body style={{ minWidth:'250px'}}>
                                <Button  onClick={scrollToForm}  variant="primary" size="sm" block style={{ backgroundColor: '#476C48'}}>
                                    Get Started
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className={'pricing-content-object'}>
                        <Card style={{ border: 'none'}} className={'pricing-card'}>
                            <Card.Body style={{ padding: '0px'}} >
                                <Card.Title style={{marginBottom:'0px'}}>
                                    <div className={'pricing-card-header-large'}>
                                        <h2 className={'pricing-header-text1'}>Large Project</h2>
                                        <h3 className={'pricing-header-text2'}>20+ hours</h3>
                                    </div>
                                </Card.Title>
                            </Card.Body>
                            <ListGroup style={{ color: '#000000', minWidth:'250px', textAlign:'center'}} className={'pricing-content-list'}>
                                <ListGroupItem>Systems integration</ListGroupItem>
                                <ListGroupItem>Full accounting system implementation</ListGroupItem>
                                <ListGroupItem>Custom reporting solutions</ListGroupItem>
                                <ListGroupItem>Accounting cleanup/streamlining</ListGroupItem>
                            </ListGroup>
                            <Card.Body style={{ minWidth:'250px'}}>
                                <Button  onClick={scrollToForm}  variant="primary" size="sm" block style={{ backgroundColor: '#334D33'}}>
                                    Get Started
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                   
                    </div>
                
                
            </div>
        </div>
    );
}