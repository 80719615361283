import React from 'react';
import './privacy.css'

const headers = [
    'LAST UPDATE OF THIS PRIVACY POLICY – 10.18.2020',
    'BEFORE USING OUR SITES, PLEASE READ THIS PRIVACY POLICY CAREFULLY.',
    'COLLECTION OF PERSONAL INFORMATION',
    'HOW DOES COMPANY USE INFORMATION GATHERED ABOUT ONLINE VISITORS?',
    'PROMOTIONAL AND INFORMATIONAL OFFERS',
    'DISCLOSURE OF INFORMATION',
    'COOKIES',
    'PROTECTION OF CHILDREN ONLINE',
    'ADDITIONAL TERMS FOR CERTAIN WEBSITES',
    'PROTECTION OF INFORMATION:',
    'POLICY CHANGE:',
    'ACCEPTANCE OF OUR PRIVACY POLICY:',
];

const p1 = [
    'This Privacy Policy is applicable to Vertical Axis Consulting Inc. ("Company") and sets out our policy on the gathering and use of information on this site and our other sites (collectively "Sites"). The Company is committed to providing safe web sites for visitors of all ages and has implemented this Privacy Policy to demonstrate our firm commitment to your privacy. The Company complies with Canadian Federal and Provincial privacy laws and regulations including the Personal Information and Electronic Documents Act.',
    'There may be links from our Sites to other web sites; note that this Privacy Policy applies only to our Sites and not to web sites of other companies or organizations to which our Sites may be linked. You must check on any linked sites for the privacy policy that applies to that site and/or make any necessary inquiries in respect of that privacy policy with the operator of the linked site. These links to third party websites are provided as a convenience and are for informational purposes only. The Company does not endorse, and is not responsible for, these linked websites.',
    'Although you are not required to register to access our Sites, you may be asked to provide us with personal information when you visit certain sections of our Sites. Your use of our Sites signifies your acknowledgement and consent to our Privacy Policy. If you do not agree to this Privacy Policy, please do not continue to use our Sites. Your continued use of the Sites signifies your acceptance of these terms and any changes in effect at the time of use.',
    'Personal Information is information about you that identifies you as an individual, for example, your name, address, e-mail address, or telephone number.',
    'We collect information that you voluntarily provide to us through responses to surveys, search functions, questionnaires, feedback, Tell Your Story forms and the like. We may also ask you to provide additional information such as your e-mail address if you want to obtain additional services, information, participate in a contest or to resolve complaints or concerns.',
    'Before forwarding us any personal information, please be advised that any information gathered on our Sites may be used in the aggregate for research and development relating to our Sites and/or for future site development and, if you ask us to, to send you promotional materials. In particular, we may use information gathered about you for the following purposes: to monitor interest in our range of products and to assist us to tailor the content of our Sites to your needs by collecting information about your preferences through tracking of patterns page views on our Sites; to create a profile relating to you in order to show you the content that might be of interest to you and to display the content according to your preferences; and, in circumstances where you have indicated that you wish to receive additional information, to send you information about us and promotional material about our products together with details of any offers we may have available from time to time.',
    'With the permission of an online visitor, information submitted at the time of registration or submission may be used for marketing and promotional purposes by the Company provided notice of this fact is made available online. If a visitor objects to such use for any reason, he/she may prevent that use, either by e-mail request or by modifying the registration information provided. The Company uses reasonable efforts to maintain visitors\' information in a secure environment. If you have submitted personal information and want to change it or opt-out, please contact us as described below.',
    'The Company will not disclose personal information that you provide on its Sites to any third parties other than to a Company agent except: i) in accordance with the terms of this Privacy Policy, or ii) to comply with legal requirements such as a law, regulation, warrant, subpoena or court order, and/or iii) if you are reporting an adverse event/side effect, in which case the Company may be required to disclose such information to bodies such as, but not limited to, Canadian and/or international regulatory authorities. Please note that any of these disclosures may involve the storage or processing of personal information outside of Canada and may therefore be subject to different privacy laws than those applicable in Canada, including laws that require the disclosure of personal information to governmental authorities under circumstances that are different than those that apply in Canada.',
    'The Company, in common with many web site operators, may use standard technology called "cookies" on its Sites. Cookies are small data files that are downloaded onto your computer when you visit a particular web site. You can disable cookies by turning them off in your browser; however, some areas of the Sites may not function properly if you do so.',
    'The Company considers the protection of children\'s privacy, especially online, to be of the utmost importance. We do not knowingly collect or solicit personal information from children nor do we allow them to become registered users of, or to request information through, our Sites or help-seeking information lines.',
    'The following additional information applies to our Sites that require registration. Generally, you are not required to provide personal information as a condition of using our Sites, except as may be necessary to provide you with a product or service that you have requested.',
    'Our Commitment to Security:\n' +
    '\n' +
    'We have put in place physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access, maintain data security, and correctly use the information we collect online. The Company applies security safeguards appropriate to the sensitivity of the information, such as retaining information in secure facilities and making personal information accessible only to authorized employees on a need-to-know basis.',
    'Storage of Information:\n' +
    '\n' +
    'Personal information you share with us is stored on our database servers at Company data centers (in whatever country they may be located), or hosted by third parties who have entered into agreements with us that require them to observe our Privacy Policy.',
    'If we alter our Privacy Policy, any changes will be posted on this page of our Site so that you are always informed of the information we collect about you, how we use it and the circumstances under which we may disclose it.',
    'By using this Site or any other The Company Site or interactive banner ads, you signify your acceptance of our Privacy Policy, and you adhere to the terms and conditions posted on the Site. By submitting your information, you agree that it will be governed by our Privacy Policy.',
]

export default function PrivacyContent() {

    return (
        <div className={'privacy-content'}>
            <h1>{headers[0]}</h1>
            <h1>{headers[1]}</h1>
            <p>{p1[0]}</p>
            <p>{p1[1]}</p>
            <p>{p1[2]}</p>
            <h1>{headers[2]}</h1>
            <p>{p1[3]}</p>
            <p>{p1[4]}</p>
            <h1>{headers[3]}</h1>
            <p>{p1[5]}</p>
            <h1>{headers[4]}</h1>
            <p>{p1[6]}</p>
            <h1>{headers[5]}</h1>
            <p>{p1[7]}</p>
            <h1>{headers[6]}</h1>
            <p>{p1[8]}</p>
            <h1>{headers[7]}</h1>
            <p>{p1[9]}</p>
            <h1>{headers[8]}</h1>
            <p>{p1[10]}</p>
            <h1>{headers[9]}</h1>
            <p>{p1[11]}</p>
            <p>{p1[12]}</p>
            <h1>{headers[10]}</h1>
            <p>{p1[13]}</p>
            <h1>{headers[11]}</h1>
            <p>{p1[14]}</p>




        </div>
    )

}