import React, {FormEvent, useState} from 'react';
import ReactModal from 'react-modal';
import './leadform.css';
import PrivacyContent from "./privacy";
import {api} from '../../axios/axios'

export default function LeadForm() {

    const [modalShown, setModalShown] = useState(false);

    const showModal = () => {
        setModalShown(true);
    }

    const hideModal = () => {
        setModalShown(false);
    }

    const submitLead = async (event: FormEvent) => {
        event.preventDefault();
        const form = document.getElementById('form');
        const button = document.getElementById('submit_button');
        // @ts-ignore
        const data = new FormData(event.target)
        await api.post('', data)
            .then(function (response) {
                if (form) {
                    // @ts-ignore
                    form.reset();
                    // @ts-ignore
                    button.className = 'valid-submit'
                }
            })
            .catch(function (error) {
                console.error('Error: ' + error)
            });
    };


    return (
        <div className={'leadform-wrapper'} id={'leadform-wrapper'}>
            <div className={'leadform-content'}>
                <div className={'leadform-content-form'}>
                    <div className={'leadform-content-form-content'}>


                        <div className={'leadform-content-form-content-header'}>
                            <h1>Interested?</h1>
                        </div>

                        <div className={'leadform-content-form-content-text'}>
                            <p>Let's chat! We'd love to have a conversation about how we can help your business!</p>
                            <p>Call us at 437-344-3702 or fill out our contact form below:</p>
                        </div>

                        <div className={'leadform-content-form-content-form'}>
                            <form id={'form'} onSubmit={submitLead}>
                                <input type={'text'} id={'first_name'} name={'first_name'} placeholder={'First Name *'}
                                       required={true}/>
                                <input type={'text'} id={'last_name'} name={'last_name'} placeholder={'Last Name *'}
                                       required={true}/>
                                <input type={'email'} id={'email'} name={'email'} placeholder={'Email *'}
                                       required={true}/>
                                <input type={'text'} id={'phone'} name={'phone'} placeholder={'Phone Number'}/>
                                <input type={'text'} id={'company'} name={'company'} placeholder={'Company'}/>
                                <textarea id={'text'} name={'text'} placeholder={'What can we help with?'}/>
                                <button id={'submit_button'} className={'pre-submit'} type={'submit'}>Contact Us</button>
                            </form>
                        </div>

                        <div className={'leadform-content-form-content-privacy'}>
                            <p>* We won't share your information with anyone! Learn more about our <a onClick={showModal}>Privacy Policy.</a></p>
                        </div>

                    </div>
                </div>
                <ReactModal
                    isOpen={modalShown}
                    onRequestClose={hideModal}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    style={{
                        overlay: {
                            zIndex: 10000,
                        }
                    }}
                >
                    <PrivacyContent/>
                    <button onClick={hideModal} style={{float: 'right'}}>CLOSE</button>
                </ReactModal>
            </div>
        </div>
    );

}