import React, {useEffect, useState} from 'react'
import './navbar.css'

import {ReactComponent as Hamburger} from "../../assets/menu.svg";
import {ReactComponent as VALogo} from "../../assets/VA_Logo_RGB.svg";

export default function NavBar() {

    const [isLarge, setLarge] = useState(window.innerWidth > 768);

    const updateSize = () => {
        setLarge(window.innerWidth > 768);
    };

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener('resize', updateSize);
    });

    const scrollToElement = (elementID: string) => {
        const element = document.getElementById(elementID);
        if (element)
            element.scrollIntoView({block: 'start', behavior: 'smooth'});
        toggleNav();
    };

    const scrollToAbout = () => {
        scrollToElement('about-wrapper');
    };

    const scrollToServices = () => {
        scrollToElement('services-wrapper');
    };

    const scrollToProcess = () => {
        scrollToElement('process-wrapper');
    };

    const scrollToPricing = () => {
        scrollToElement('pricing-wrapper');
    };

    const scrollToTestimonial = () => {
        scrollToElement('testimonial-wrapper');
    };

    const scrollToContact = () => {
        scrollToElement('leadform-wrapper');
    };

    const toggleNav = () => {
        const nav = document.getElementById('navbar-small-nav');
        if (nav && !isLarge) {
            if (nav.classList.contains('navbar-small-nav-show')) {
                nav.classList.remove('navbar-small-nav-show');
            } else {
                nav.classList.add('navbar-small-nav-show');
            }
        }
    };

    const smallNavBar = () => {
        return (
            <>
                <div className={'navbar-small'} id={'navbar-small'}>
                    <button className={'navbar-small-logo'}>
                        <VALogo height={50} width={50}/>
                    </button>

                    <h1 className={'navbar-small-text'}>
                        Vertical Axis Consulting
                    </h1>

                    <button className={'navbar-small-hamburger'} onClick={toggleNav}>
                        <Hamburger height={40} width={40}/>
                    </button>
                </div>
                <nav className={'navbar-small-nav'} id={'navbar-small-nav'}>
                    <ul>
                        <li><a href={'javascript:void(0)'} onClick={scrollToAbout}>About Us</a></li>
                        <li><a href={'javascript:void(0)'} onClick={scrollToServices}>Services</a></li>
                        <li><a href={'javascript:void(0)'} onClick={scrollToProcess}>Process</a></li>
                        <li><a href={'javascript:void(0)'} onClick={scrollToPricing}>Pricing</a></li>
                        <li><a href={'javascript:void(0)'} onClick={scrollToTestimonial}>Testimonial</a></li>
                        <li><a href={'javascript:void(0)'} onClick={scrollToContact}>Contact Us</a></li>
                    </ul>
                </nav>
            </>
        );
    };

    const largeNavBar = () => {
        return (
            <>
                <div className={'navbar-large'} id={'navbar-large'}>

                    <div className={'navbar-large-left'}>
                        <button className={'navbar-large-logo'}>
                            <VALogo height={50} width={50}/>
                        </button>

                        <h1 className={'navbar-large-text'}>
                            Vertical Axis Consulting
                        </h1>
                    </div>

                    <nav className={'navbar-large-nav'} id={'navbar-large-nav'}>
                        <ul>
                            <li><a href={'javascript:void(0)'} onClick={scrollToAbout}>About Us</a></li>
                            <li><a href={'javascript:void(0)'} onClick={scrollToServices}>Services</a></li>
                            <li><a href={'javascript:void(0)'} onClick={scrollToProcess}>Process</a></li>
                            <li><a href={'javascript:void(0)'} onClick={scrollToPricing}>Pricing</a></li>
                            <li><a href={'javascript:void(0)'} onClick={scrollToTestimonial}>Testimonial</a></li>
                            <li><a href={'javascript:void(0)'} onClick={scrollToContact}>Contact Us</a></li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    };

    return (
        <div className={'navbar-wrapper'} id={'navbar-wrapper'}>
            {isLarge ? largeNavBar() : smallNavBar()}
        </div>
    );
}
