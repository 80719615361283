import React, {CSSProperties} from 'react';
import './testimonial.css';
import {ReactComponent as CALogo} from "../../assets/Nerve_Strategic_Inc_1.svg";
import {ReactComponent as ARLogo} from "../../assets/AREACODELogo.svg";
import {ReactComponent as SLLogo} from "../../assets/shoplocalhamilton.svg";
import {Carousel} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

const scrollToForm = () => {
    const leadForm = document.getElementById('leadform-wrapper');
    if (leadForm)
        leadForm.scrollIntoView({block: 'start', behavior: 'smooth'});
};

type TestimonialProps = {
    skew: string,
};


export default function Testimonial() {
    
const dataslide = [
    {
        "description": "Vertical axis has given us critical components we were lacking in our operations as we expand and change in our company.  Trevor quickly cleaned our data and built us a new system for time tracking and invoicing that is easy to use. In fact, Trevor helped us find \"lost hours\" we weren't billing, thousands and thousands of dollars! I know we will go back to Vertical Axis the next time we need operational support.",
        "business": "Marjorie Malpass",
        "person": "Founder, Nerve Strategic Inc",
        "logo": CALogo
    },
    {
        "description": "Trevor has been an immense asset to my small consulting practice in ways that far exceeded my expectations. He has devised some ingenious and unique solutions tailored to my business - automating monthly invoicing, forecasting reports, profit planning, and tracking time spent against estimated hours.<br/>Trevor has been extremely responsive and quick to deliver impressive results in creatively finding integrations between my preferred software apps and platforms (Google Sheets and Wave app). If you’re looking for a true professional to make brilliant suggestions and deliver top notch service, don’t hesitate to bring Vertical Axis on board. Always diligent and a pleasure to work with!",
        "business": "Darcie Watson, Principal",
        "person": "Areacode Architects, OAA ARIDO",
        "logo": ARLogo
    }
];
      

    return (
        <div className={'testimonial-wrapper'} id={'testimonial-wrapper'}>
            <div className={'testimonial-content-wrapper'}>

                <div className={'testimonial-content-title'}>
                    <h1>What Clients Say About Us</h1>
                </div>

                <div className={'testimonial-content-objects'}>
                        <Carousel>
                            {/* {dataslide.map(item => (
                            <Carousel.Item style={{ padding: '50px'}}>
                                <div className={'testimonial-content-text-wrapper'} > 
                                    <item.logo style={{ width: '100px', height: '100px', marginTop:'-60px' }}/>
                                    <p>{item.description}</p>
                                    <h3>{item.business}</h3>
                                    <p>{item.person}</p>
                                </div>
                            </Carousel.Item>
                            ))}; */}
                            <Carousel.Item style={{ padding: '50px'}} interval={25000}>
                                <div className={'testimonial-content-text-wrapper'} > 
                                    <a href="https://nervestrategic.com/" target="_blank"><CALogo style={{ width: '150px', height: '100px', marginTop:'-60px' }}/></a>
                                    <p>Vertical axis has given us critical components we were lacking in our operations as we expand and change in our company.  Trevor quickly cleaned our data and built us a new system for time tracking and invoicing that is easy to use. In fact, Trevor helped us find "lost hours" we weren't billing, thousands and thousands of dollars! I know we will go back to Vertical Axis the next time we need operational support.</p>
                                    <h3>Marjorie Malpass</h3>
                                    <a href="https://nervestrategic.com/" target="_blank"><p>Founder, Nerve Strategic Inc</p></a>
                                </div>
                            </Carousel.Item>

                            <Carousel.Item style={{ padding: '50px'}} interval={25000}>
                                <div className={'testimonial-content-text-wrapper'} > 
                                <a href="https://www.areacodearchitects.com/" target="_blank"><ARLogo style={{ width: '150px', height: '100px', marginTop:'-65px' }}/></a>
                                    
                                    <p>Trevor has been an immense asset to my small consulting practice in ways that far exceeded my expectations. He has devised some ingenious and unique solutions tailored to my business - automating monthly invoicing, forecasting reports, profit planning, and tracking time spent against estimated hours.<br/>Trevor has been extremely responsive and quick to deliver impressive results in creatively finding integrations between my preferred software apps and platforms (Google Sheets and Wave app). If you’re looking for a true professional to make brilliant suggestions and deliver top notch service, don’t hesitate to bring Vertical Axis on board. Always diligent and a pleasure to work with!</p>
                                    <h3>Darcie Watson, Principal</h3>
                                    <a href="https://www.areacodearchitects.com/" target="_blank"><p>Areacode Architects, OAA ARIDO</p></a>
                                    
                                </div>
                            </Carousel.Item>

                            <Carousel.Item style={{ padding: '50px'}} interval={25000}>
                                <div className={'testimonial-content-text-wrapper'} > 
                                <a href="https://shoplocalhamilton.ca/" target="_blank"><SLLogo style={{ width: '100px', height: '70px', marginTop:'-65px' }}/></a>
                                    
                                    <p>We have found that there is a LOT to do to get a business up and running! One thing that is very important to do is keep a good track of all the finances. We are not financial experts, but Vertical Axis is! With much help on his part, we have set up all the parts needed to get on our feet. What a great fit. Trevor has been so helpful in getting us started and is patient with teaching us how to navigate all the accounting we need for our start-up</p>
                                    <h3>Rob Farquharson</h3>
                                    <a href="https://shoplocalhamilton.ca/" target="_blank"><p>Co-founder, Shop Local Mytown Inc.</p></a>
                                    
                                </div>
                            </Carousel.Item>

                        </Carousel>
                    </div>
            </div>
        </div>
    );
}