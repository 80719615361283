import React from 'react';
import './about.css'

import va_logo from './VerticalAxis_logo_RGB_stacked.png';
import {ReactComponent as VALogo} from "../../assets/VA_Logo_RGB.svg";

const scrollToForm = () => {
    const leadForm = document.getElementById('leadform-wrapper');
    if (leadForm)
        leadForm.scrollIntoView({block: 'start', behavior: 'smooth'});
}

export default function About() {

    const p1 = 'At Vertical Axis, we identify, challenge, and create formalized, streamlined operational systems and processes for your business.';
    const p2 = 'Our drive is to build accessible solutions that save you time, money, reduce overall risk exposure, and to protect your company\'s vision.';
    const p3 = 'It is our imperative to support your dream with custom solutions that deliver operational excellence.';

    const pAll = "  " + p1 + " " + p2 + " " + p3;

    const p1_new = "At Vertical Axis it is our imperative to support your dream with custom solutions that deliver operational excellence."
    const p2_new = "We identify challenges and create formalized, streamlined operational systems and processes to solve them. Our drive is to build accessible solutions that save you time, money, reduce overall risk exposure, and protect your company's vision."

    return (
        <div className={'about-wrapper'} id={'about-wrapper'}>
            <div className={'about-content'}>
                <div className={'about-content-logo'}>
                    <VALogo className={'about-content-logo-svg'}/>
                </div>
                <div className={'about-content-text'}>
                    <div className={'about-content-text-header'}>
                        <h1>Vertical Axis</h1>
                        <h1>Consulting</h1>
                    </div>
                    <div className={'about-content-text-description'}>
                        {/*<p>{pAll}</p>*/}
                        <p>{p1_new}</p>
                        <p>{p2_new}</p>
                        <p>We are experts in rapid implementation of a variety of out-of-the-box and/or custom solutions.  From online stores to invoicing and payment solutions, let our team help your company get to the next level!</p>
                        <p>Vertical Axis gives you more time in your day so you can focus on what got you into business in the first place.  Outsource the project, own the solution.</p>
                        <button onClick={scrollToForm}>Contact Us</button>
                    </div>
                </div>
            </div>
        </div>
    );

}
