import React, {useState} from 'react';
import ReactModal from 'react-modal';
import PrivacyContent from "../leadform/privacy";

import './footer.css'

import {ReactComponent as VALogo} from "../../assets/VA_Logo_RGB.svg";
import {ReactComponent as LILogo} from "../../assets/LinkedInLogo.svg";

export default function Footer() {

    const VA_LI_Link = 'https://www.linkedin.com/company/vertical-axis-consulting';

    const [modalShown, setModalShown] = useState(false);

    const showModal = () => {
        setModalShown(true);
    }

    const hideModal = () => {
        setModalShown(false);
    }

    const scrollToElement = (elementID: string) => {
        const element = document.getElementById(elementID);
        if (element)
            element.scrollIntoView({block: 'start', behavior: 'smooth'});
    };

    const scrollToAbout = () => {
        scrollToElement('about-wrapper');
    };

    const scrollToServices = () => {
        scrollToElement('services-wrapper');
    };

    const scrollToProcess = () => {
        scrollToElement('process-wrapper');
    };

    const scrollToPricing = () => {
        scrollToElement('pricing-wrapper');
    };

    const scrollToTestimonials = () => {
        scrollToElement('testimonial-wrapper');
    };

    const scrollToContact = () => {
        scrollToElement('leadform-wrapper');
    };

    return (
        <div className={'footer-wrapper'}>
            <div className={'footer-content'}>

                <div className={'footer-content-logo'}>
                    <VALogo className={'footer-content-logo-svg'}/>
                </div>

                <div className={'footer-content-sections'}>
                    <li><a href={'javascript:void(0)'} onClick={scrollToAbout}>About Us</a></li>
                    <li><a href={'javascript:void(0)'} onClick={scrollToServices}>Services</a></li>
                    <li><a href={'javascript:void(0)'} onClick={scrollToProcess}>Process</a></li>
                    <li><a href={'javascript:void(0)'} onClick={scrollToPricing}>Pricing</a></li>
                    <li><a href={'javascript:void(0)'} onClick={scrollToTestimonials}>Testimonials</a></li>
                    <li><a href={'javascript:void(0)'} onClick={scrollToContact}>Contact Us</a></li>
                    <li><a href={'javascript:void(0)'} onClick={showModal}>Privacy</a></li>
                </div>

                <div className={'footer-content-linkedin'}>
                    <a href={VA_LI_Link} target={'_blank'}>
                        <LILogo className={'footer-content-linkedin-svg'}/>
                    </a>
                </div>

                <div className={'footer-content-powered-by'}>
                    Powered by MagnusTech Canada.
                </div>

            </div>

            <ReactModal
                isOpen={modalShown}
                onRequestClose={hideModal}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                style={{
                    overlay: {
                        zIndex: 10000,
                    }
                }}
            >
                <PrivacyContent/>
                <button onClick={hideModal} style={{float: 'right'}}>CLOSE</button>
            </ReactModal>

        </div>
    );

}
